<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        title="订单"
        width="500px"
    >

        <div class="order_container">
            <div style="text-align: center;font-size: 28px;">
                {{ order.menuName || 'svip'}}
            </div>
            <div style="text-align: center; margin-top: 10px;">
                <span style="font-size: 20px; color: #216FF8;">¥ {{ order.noBillPrice || order.menuPrice || 640 }} 元</span>/
                <span style="color:#999999;text-decoration: line-through;"> {{ order.menuPrice || 640 }} 元</span>
            </div>
            <div style="padding: 5px; box-sizing: border-box; border: 1px solid #216FF8; border-radius: 3px; width: 140px; margin: 10px auto;">不开发票打8折优惠</div>
        </div>
        <div class="pay_container">
            <div class="qr">
                <div style="width: 140px; height: 140px;">
                    <canvas style="width: 100%; height: 100%;" id="canvas" ref="qrcodeCanvas"></canvas>
                </div>
                <div style="display: flex; justify-content: center;width: 140px; margin-top: 20px;">
                    <div>
                        <img src="../../../assets/vip/wechatPay.png" style="width: 25px; height: 25px;" alt="">
                    </div>
                    <div style="font-size: 18px; margin-top: 4px; margin-left: 5px;">微信支付</div>
                </div>
            </div>
            <div style="margin-left: 40px;">
                <div style="font-size: 26px; color: #000;">应付金额</div>
                <div style="font-size: 26px; margin-top: 40px;">
                    <span style="font-size: 20px; color: #216FF8;">¥ {{ checked ? order.menuPrice : order.noBillPrice }} 元</span>
                </div>
                <div style="font-size: 26px; margin-top: 40px;">
                    <el-checkbox v-model="checked">是否开发票</el-checkbox>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import {$pay} from "@/services"
import QRCode from 'qrcode'
import $cookies from "vue-cookies";
export default {
    name:'payAdd',
    data() {
        return {
            dialogVisible:false,
            svipList:[],
            vipList:[],
            order:{},
            checked:false,
            orderId:'',
            orderTimer:null
        }
    },
    methods:{
        setFlag(data) {
            const that = this
            that.order = data
            let params = {}
            params.id = data.menuId
            params.noBill = 1
            params.email = $cookies.get('userInfo').email
            if(that.orderTimer) {
                clearTimeout(that.orderTimer)
            } 
            $pay.order(params).then(res => {
                if (res.code ===200) {
                    that.orderId = res.data.orderId
                    that.dialogVisible = true
                    that.getOrderStatus(that.orderId)
                    that.$nextTick(() => {
                        that.generateQRCode(res.data.url)
                    })
                } else {
                    that.$message.error('系统错误,晴联系管理员')
                }
            })
            
        },
        getOrderStatus(id) {
            const that = this
            if (that.orderTimer) {
                clearTimeout(that.orderTimer)
            }
            that.orderTimer = setTimeout(() => {
                $pay.orderStatus({orderId:id}).then(res => { 
                    if (res.code === 200) {
                        if (res.data === 1) {
                            this.$message.success('支付成功')
                            clearTimeout(that.orderTimer)
                            this,this.dialogVisible = false
                        } else {
                            that.getOrderStatus(that.orderId)
                        }
                    }
                })
            },1000)
        },
        generateQRCode(url) {
            const canvas = document.getElementById('canvas')
            QRCode.toCanvas(canvas,url,error => {
                if (error) {
                    this.$message.error('二维码生成错误')
                }
            })
        },
        getList() {
            $pay.comboList({pageNum:1,pageSize:999,menuStatus:0}).then(res => {
                let data = res.data.records
                data.forEach(item => {
                    if (item.menuName === 'svip') {
                        this.svipList.push(item)
                    } else if (item.menuName === 'svip') {
                        this.vipList.push(item)
                    }
                })
            })
        },
    }
}
</script>
<style lang="less" scoped>
.order_container {
    width: 174px;
    height: 126px;
    background: #F4F8FF;
    border-radius: 11px 11px 11px 11px;
    border: 2px solid #216FF8;
    padding-top: 10px;
}
.pay_container {
    margin-top: 40px;
    background: #F8F9FA;
    padding: 20px;
    display: flex;
}

</style>