<template>
  <div class="container">
    <div class="title">开通VIP，获得更多权限</div>
    <div class="order_list">
        <div>
            <div v-for="(item,index) in col" :key="index" class="col_item">
                {{ item }}
            </div>
        </div>
        <div 
            class="card"
            v-for="order in orderList"
            :key="order.id"
            :style="{background: order.name === 'svip' ? '#FFE194' : '#fff'}"
        >
            <div style="margin-top: 40px; text-align: center; height: 33px;">
                <img src="../../assets/vip/svip.png" v-if="order.name === 'svip'" alt="">
                <img src="../../assets/vip/vip.png" v-else-if="order.name === 'vip'" alt="">
                <span v-else>
                    {{ order.name }}
                </span>
            </div>
            <!-- 地图展示站点 -->
            <div style="text-align: center; margin-top: 20px;">
                <img 
                    :src="order.name === 'svip' ? require('@/assets/vip/svip_right.png') : require('@/assets/vip/right.png')" 
                    v-if="order.viewStation"
                >
                <img v-else src="../../assets/vip/error.png" alt="">
            </div>
            <!-- 单站数据 -->
            <div style="text-align: center; margin-top: 35px;">
                <img 
                    :src="order.name === 'svip' ? require('@/assets/vip/svip_right.png') : require('@/assets/vip/right.png')" 
                    v-if="order.stationData"
                >
                <img v-else src="../../assets/vip/error.png" alt="">
            </div>
            <!-- 免费ppt -->
            <div style="text-align: center; margin-top: 35px;">
                <img 
                    :src="order.name === 'svip' ? require('@/assets/vip/svip_right.png') : require('@/assets/vip/right.png')" 
                    v-if="order.viewPPT"
                >
                <img v-else src="../../assets/vip/error.png" alt="">
            </div>
            <!-- 全站ppt -->
            <div style="text-align: center; margin-top: 35px;">
                <img 
                    :src="order.name === 'svip' ? require('@/assets/vip/svip_right.png') : require('@/assets/vip/right.png')" 
                    v-if="order.viewAllPPT"
                >
                <img v-else src="../../assets/vip/error.png" alt="">
            </div>
            <!-- 历史数据 -->
            <div style="text-align: center; margin-top: 35px;">
                <img 
                    :src="order.name === 'svip' ? require('@/assets/vip/svip_right.png') : require('@/assets/vip/right.png')" 
                    v-if="order.viewHistory"
                >
                <img v-else src="../../assets/vip/error.png" alt="">
            </div>
            <!-- 日数据 -->
            <div style="text-align: center; margin-top: 40px;">
                每月{{ order.dayFile }}次
            </div>
            <!-- 周数据 -->
            <div style="text-align: center; margin-top: 40px;">
                每月{{ order.weekFile }}次
            </div>
            <!-- 月数据 -->
            <div style="text-align: center; margin-top: 40px;">
                每月{{ order.monthFile }}次
            </div>
            <!-- 价格 -->
            <div style="text-align: center; margin-top: 40px;" v-if="order.menuPrice">
                <span style="font-size: 20px; font-weight: 600;">
                    ¥ {{ order.menuPrice }}/
                </span>
                <span>
                    {{ order.menuTime }}天
                </span>
            </div>
            <div style="text-align: center; margin-top: 20px;" v-if="order.menuPrice">
                <el-button
                    :style="{
                        background : order.name === 'svip' ? '#786128' : '#216FF8',
                        color: '#fff'
                    }"
                    @click="goPay(order)"
                > 
                    立即开通
                </el-button>
            </div>
        </div>
    </div>
    <payAddVue ref="payOrder" />
  </div>
</template>

<script>
import {$pay} from "@/services"
import payAddVue from '../gis/components/payAdd.vue'
export default {
    components: {
        payAddVue
    },
    data() {
        return {
            col:[
                '权益',
                '地图上站点实时数据查看',
                '单站实时详细数据查看',
                '部分网站PPT学习资源',
                '全部网站PPT学习资源',
                '历史数据查看',
                '日数据文件下载',
                '周数据文件下载',
                '月数据文件下载',
                '价格'
            ],
            orderList:[
           
            ]
        }
    },
    created() {
        this.getList()
    },
    methods:{
        getList() {
            $pay.comboList({pageNum:1,pageSize:999,menuStatus:0}).then(res => {
                let data = res.data.records
                data.forEach(item => {
                    item.name = item.menuName
                    if (item.menuType === 1) {
                        item.viewStation = true
                        item.stationData = true
                        item.viewPPT = true
                        item.viewAllPPT = true
                        item.viewHistory = true
                    } else if (item.menuType === 0) {
                        item.viewStation = true
                        item.stationData = true
                        item.viewPPT = true
                        item.viewAllPPT = false
                        item.viewHistory = false
                    }
                })
                this.orderList = data
            })
        },
        goPay(data) {
            this.$refs.payOrder.setFlag(data)
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient( 180deg, #F6F9FF 0%, #F8FBFF 100%);
    padding-top: 150px;
    .title {
        font-size: 38px;
        text-align: center;
    }
    .order_list {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .col_item {
            text-align: right;
            width: 180px;
            margin-top: 40px;
        }
        .card {
            width: 158px;
            height: 640px;
            background: #fff;
            border-radius: 15px 15px 15px 15px;
            margin-left: 20px;
        }
    }
}
</style>